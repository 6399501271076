<template>
  <PageLayout>
    <template #header>
      <h1>{{ $t('routes.documents') }}</h1>
    </template>
    <template #dashboard>
      <div v-if="loading" class="col-4 h-100">
        <CardLoadingSkeleton />
      </div>
      <div v-else class="col-4 h-100">
        <div class="card">
          <div class="card-header">
            {{ $t('document.documentsOverview.dashboard.recordedDocuments.title') }}
          </div>
          <div class="card-body d-flex flex-column align-items-center text-center py-5">
            <div>
              <h1>{{ currentMonthCount }}</h1>
              <small>
                {{ $t('document.documentsOverview.dashboard.recordedDocuments.passMonth', { date }) }}
              </small>
            </div>
            <div class="dropdown-divider w-50" />
            <div>
              <h1>{{ soFarCount }}</h1>
              <small>
                {{ $t('document.documentsOverview.dashboard.recordedDocuments.totalDocuments') }}
              </small>
            </div>
          </div>
        </div>
      </div>
      <div v-if="loading" class="col h-100">
        <CardLoadingSkeleton />
      </div>
      <div v-if="!loading" class="col h-100">
        <div class="card">
          <div class="card-header d-flex justify-content-between">
            {{ $t('document.documentsOverview.dashboard.processedDocuments.title') }}
            <UploadFile v-if="isUploadPermitted" multiple @upload-success="refetchData">
              <Button type="text" class="p-0 text-typography-primary">
                <UploadIcon />
                {{ $tc('document.uploadFiles', 0) }}
              </Button>
            </UploadFile>
          </div>
          <div class="card-body d-flex justify-content-around align-items-center text-center p-7">
            <div>
              <div class="text-info">
                <DocumentOrganizationIcon height="36px" width="36px" />
              </div>
              <h3>
                {{
                  $t('document.documentsOverview.dashboard.processedDocuments.files', {
                    count: organizationTasksCount,
                  })
                }}
              </h3>
              <small>
                {{ $t('document.documentsOverview.dashboard.processedDocuments.organization') }}
              </small>
            </div>
            <div>
              <div class="text-info">
                <DocumentApprovedIcon height="36px" width="36px" />
              </div>
              <h3>
                {{
                  $t('document.documentsOverview.dashboard.processedDocuments.documents', {
                    count: uploadTasksCount,
                  })
                }}
              </h3>
              <small>
                {{ $t('document.documentsOverview.dashboard.processedDocuments.classification') }}
              </small>
            </div>
            <div>
              <div class="text-info">
                <DocumentWaitingIcon height="36px" width="36px" />
              </div>
              <h3>
                {{
                  $t('document.documentsOverview.dashboard.processedDocuments.documents', {
                    count: recordTasksCount,
                  })
                }}
              </h3>
              <small>
                {{ $t('document.documentsOverview.dashboard.processedDocuments.record') }}
              </small>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #tabs>
      <div v-if="loading">
        <h2 class="placeholder-wave tabs-skeleton-container">
          <span class="placeholder bg-secondary skeleton-h2"></span>
        </h2>
        <p class="placeholder-wave tabs-skeleton-container skeleton-p">
          <span class="placeholder bg-secondary"></span>
        </p>
      </div>
      <Tabs v-else :tabs="tabs" :active-tab.sync="activeTab" />
    </template>

    <div v-for="{ component, index } in tabsContent" :key="index">
      <component :is="component" v-if="index === activeTab" />
    </div>
  </PageLayout>
</template>

<script>
import { DateTime } from 'luxon';
import { computed } from 'vue';

import { PageLayout, Tabs, Button, CardLoadingSkeleton } from '@/modules/core';
import { DocumentApprovedIcon, DocumentWaitingIcon, UploadIcon, DocumentOrganizationIcon } from '@/assets/icons';
import { useTenancy } from '@/modules/auth';
import { useUserPermissions } from '@/modules/auth/compositions';
import { USER_PERMISSIONS_LIST } from '@/permissions';

import { useDocuments, useDocumentsAggregationNew3, useDocumentTasks } from '@/modules/document/compositions';
import {
  TenantDocumentsTable,
  ClassificationOverview,
  OrganizationOverview,
  RecordingTasks,
  UploadFile,
  UnRecordedDocumentsTable,
} from '@/modules/document/components';

export default {
  components: {
    PageLayout,
    Tabs,
    TenantDocumentsTable,
    UnRecordedDocumentsTable,
    ClassificationOverview,
    OrganizationOverview,
    RecordingTasks,
    DocumentApprovedIcon,
    DocumentOrganizationIcon,
    DocumentWaitingIcon,
    UploadIcon,
    UploadFile,
    Button,
    CardLoadingSkeleton,
  },
  setup() {
    const { isUserPermittedForActiveTenant } = useUserPermissions();

    const { currentTenantId } = useTenancy();
    const { aggregation: soFarAggregation, loading: soFarLoading } = useDocumentsAggregationNew3(
      computed(() => ({
        businessId: currentTenantId.value,
        recorded: true,
      }))
    );

    const { aggregation: currentMonthAggregation, loading: currentLoading } = useDocumentsAggregationNew3(
      computed(() => ({
        businessId: currentTenantId.value,
        fromDate: DateTime.utc().startOf('month').toISODate(),
        recorded: true,
      }))
    );
    const {
      documentTasks,
      loading: tasksLoading,
      refetch: refetchTasks,
    } = useDocumentTasks(() => ({ businessId: currentTenantId.value }));

    const {
      documents: unRecordedDocuments,
      loading: unRecordedDocumentsLoading,
      totalCount: unRecordedDocumentsCount,
    } = useDocuments(
      computed(() => ({
        businessId: currentTenantId.value,
        pagination: { limit: 1 },
        recorded: false,
      }))
    );

    const uploadTasksCount = computed(() => documentTasks.value.filter((task) => task.type === 'upload').length);
    const organizationTasksCount = computed(
      () => documentTasks.value.filter((task) => task.type === 'organize').length
    );
    const recordTasksCount = computed(() => documentTasks.value.filter((task) => task.type === 'record').length);
    const isManageUser = isUserPermittedForActiveTenant(USER_PERMISSIONS_LIST['document.manage']);
    const isUploadPermitted = isUserPermittedForActiveTenant(USER_PERMISSIONS_LIST['document.upload']);

    return {
      isManageUser,
      isUploadPermitted,
      organizationTasksCount,
      uploadTasksCount,
      recordTasksCount,
      currentMonthAggregation,
      soFarAggregation,
      refetchTasks,
      unRecordedDocumentsCount,
      unRecordedDocuments,
      currentMonthCount: computed(() => currentMonthAggregation.value[0]?.count ?? 0),
      soFarCount: computed(() => soFarAggregation.value[0]?.count ?? 0),
      loading: computed(
        () => currentLoading.value || soFarLoading.value || tasksLoading.value || unRecordedDocumentsLoading.value
      ),
    };
  },
  data() {
    return {
      activeTab: 0,
      date: new Date().toLocaleDateString(this.$i18n.locale, { month: 'long', year: 'numeric' }),
      loaderHandler: null,
    };
  },
  computed: {
    tabs() {
      return [
        ...(this.isManageUser
          ? [
              {
                text: this.$t('document.documentsOverview.unRecordedDocuments.title'),
                badgeValue: this.unRecordedDocumentsCount,
              },
            ]
          : []),
        { text: this.$t('document.documentsOverview.tenantDocumentsTable.title') },
        {
          text: this.$t('document.documentsOverview.organizationTasks.title'),
          badgeValue: this.organizationTasksCount,
        },
        {
          text: this.$t('document.documentsOverview.classificationTasks.title'),
          badgeValue: this.uploadTasksCount,
        },
        { text: this.$t('document.documentsOverview.recordingTasks.title'), badgeValue: this.recordTasksCount },
      ];
    },
    tabsContent() {
      const components = [TenantDocumentsTable, OrganizationOverview, ClassificationOverview, RecordingTasks];
      if (this.isManageUser) components.unshift(UnRecordedDocumentsTable);
      return components.map((component, index) => ({ component, index }));
    },
  },
  methods: {
    async sleep() {
      return new Promise((resolve) => setTimeout(resolve, 3000));
    },
    async refetchData() {
      await this.sleep();
      await this.refetchTasks();
    },
  },
};
</script>

<style>
.skeleton-h2 {
  width: 180px;
  margin-bottom: 16px;
  height: 20px;
}

.skeleton-p {
  width: 120px;
}

.tabs-skeleton-container {
  display: flex;
  flex-direction: column;
}

.count-card {
  width: 30%;
}
.dashboard-card {
  width: 70%;
  max-width: 800px;
}
</style>
