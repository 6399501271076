<template>
  <PageLayout>
    <template #header>
      <h1>{{ $t('routes.documents') }}</h1>
    </template>
    <template #tabs>
      <Tabs :tabs="tabs" :active-tab.sync="activeTab" />
    </template>

    <div>
      <div v-for="{ component, index, props } in tabsContent" :key="index">
        <component :is="component" v-bind="props" v-if="index === activeTab" />
      </div>
    </div>
  </PageLayout>
</template>

<script>
import { computed, ref } from 'vue';
import { PageLayout, Tabs, CardLoadingSkeleton } from '@/modules/core';
import { useBusinesses } from '@/modules/document/compositions';
import { UnRecordedDocumentsTableOperation, OpenTasksTableOperation } from '@/modules/document/components';

export default {
  components: {
    PageLayout,
    Tabs,
    UnRecordedDocumentsTableOperation,
    OpenTasksTableOperation,
    CardLoadingSkeleton,
  },
  setup() {
    const activeTab = ref(0);
    const { businesses: restaurants } = useBusinesses('restaurant');
    const { businesses: suppliers } = useBusinesses('supplier');
    return {
      activeTab,
      restaurants: computed(() => [...Object.values(restaurants.value)]),
      suppliers: computed(() => [...Object.values(suppliers.value)]),
    };
  },
  computed: {
    tabs() {
      return [
        { text: this.$t('document.documentsOperationOverview.unRecordedDocumentsTable.title') },
        { text: this.$t('document.documentsOperationOverview.openTasksTable.title') },
      ];
    },
    tabsContent() {
      const components = [
        {
          component: UnRecordedDocumentsTableOperation,
          props: {
            restaurants: this.restaurants,
            suppliers: this.suppliers,
          },
        },
        {
          component: OpenTasksTableOperation,
          props: {
            restaurants: this.restaurants,
          },
        },
      ];
      return components.map((component, index) => ({ ...component, index }));
    },
  },
};
</script>

<style scoped lang="scss">
.skeleton-h2 {
  width: 180px;
  margin-bottom: 16px;
  height: 20px;
}

.skeleton-p {
  width: 120px;
}

.tabs-skeleton-container {
  display: flex;
  flex-direction: column;
}

.count-card {
  width: 30%;
}
.dashboard-card {
  width: 70%;
  max-width: 800px;
}
</style>
